import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import { PageTitle } from '@molecules/PageTitle';
import EditIcon from '@assets/edit-2.svg';
import Cancel from '@assets/alert-circle.svg';
import Send from '@assets/send.png';
import file from '@assets/filesImage.png';
import ConfirmModal from '@organism/ConfimationModal';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import {
  changeEventStatus,
  EventDetailsBreadCrumbs,
  EventBreadCrumbsViewEvent,
  IEventCodeListType,
  sendMessage,
  QuoteCreateEventEventDetailsBreadcrumb
} from './helper';
import SelectInput from '@atom/Select';
import { Col, Row, Select } from 'antd';
import moment from 'moment';
import { Label } from '@atom/FormLable';
import { ReportsToType } from '@pages/EmployeeManagementPage/AddEditEmployee/helper';
import SendMessageModal from './SendMessageModal';
import { useSelector } from 'react-redux';
import { DateTimeGuestWrapper, EventHeaderWrapper } from '../style';
import BreadCrumbs from '@atom/BreadCrumb';
import { localStorageUtil } from '@utils/localstorageUtil';
import EventActionButtons from './EventInfo/EventActionButtons';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import { STATUS } from './constants';
import SurveyFormPdfViewer from '../SurveyForm/SurveyFormPdfViewer';

interface IEventDetailsHeaderProps {
  eventData: any;
  setEventData: (data: any) => void;
  eventCodeChange: (value: any) => void;
  eventCodeList: IEventCodeListType[];
  currentEventCode: any;
  getEventDetailMethod: (eventId: string) => void;
}

const EventDetailsHeader: React.FC<IEventDetailsHeaderProps> = ({
  eventData = null,
  eventCodeChange,
  eventCodeList = [],
  setEventData,
  currentEventCode = null,
  getEventDetailMethod
}) => {
  const { event_detail, venue_detail } = eventData;
  const navigate = useNavigate();
  const route = useLocation();
  const location = localStorageUtil.getValue('location');
  const userData = localStorageUtil.getValue('userData');
  const { eventId } = useParams();
  const quoteId = event_detail?.quote_id;
  const device = useWindowSizeChangedforGrid();

  const isEventConfirmed = event_detail?.status === STATUS.CONFIRM;
  const currentDate = moment();
  const isPastDate =
    event_detail?.event_date && moment(event_detail?.event_date).isBefore(currentDate, 'day');

  const user = useSelector(
    (state: { user: { isEmployee: boolean; isAdmin: boolean } }) => state.user
  );
  const { isEmployee, isAdmin } = user;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDowloadPdfModalVisible, setIsDowloadPdfModalVisible] = useState<boolean>(false);
  const [isContractDowloadPdfModalVisible, setIsContractDowloadPdfModalVisible] =
    useState<boolean>(false);

  const [isSendMessageModalVisible, setIsSendMessageModalVisible] = useState<boolean>(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);

  const isFormSubmitted: boolean = !event_detail?.survey_url;

  const isUserInEventStaffs: boolean | undefined = eventData?.event_manager_staffs?.some(
    (staff: { employee_id: string }) => staff.employee_id === userData?.user_id
  );

  const isUserIsAccountStaffs: boolean | undefined =
    event_detail?.account_manager_id === userData?.user_id;

  const showSendFeedbackButton: boolean =
    isEventConfirmed && isPastDate && isFormSubmitted && (isUserInEventStaffs || isAdmin);

  const showViewSurveyFormButton: boolean =
    (isAdmin && event_detail?.survey_url) || (isUserIsAccountStaffs && event_detail?.survey_url);

  const pathKey = location?.state?.prevPath?.includes('/quote/view/')
    ? 'quote_details'
    : location?.state?.path === 'create_event' || route?.state?.path === 'create_event'
    ? 'quote_event'
    : location?.state?.path === 'select'
    ? 'edit_event'
    : location?.state?.path;

  const onEdit = () => {
    const { from_event } = event_detail;
    const path =
      from_event === 1
        ? `/events/create/customer/${quoteId}/${eventId}/`
        : `/events/find-quote/create/${quoteId}/${eventId}/quote/`;
    localStorageUtil.setValue('location', {
      state: { prevPath: location?.pathname, eventId: eventId, path: pathKey }
    });
    navigate(path, {
      state: { prevPath: location?.pathname, eventId: eventId, path: route?.state?.path }
    });
  };

  const getStatus = () => {
    switch (event_detail?.status) {
      case 0:
        return 'CANCEL';
      case 1:
        return 'CANCEL';
      case 2:
        return 'CANCEL';
      case 3:
        return 'CANCEL';
      case 4:
        return 'UNCANCEL';
      default:
        return null;
    }
  };

  const getEventDate = () => {
    if (!event_detail?.event_date || event_detail?.event_date === '0000-00-00') {
      return <Label text="Unassigned Date" />;
    } else {
      return <Label text={moment(event_detail?.event_date).format('ddd, MMMM DD')} />;
    }
  };

  const getEventTime = () => {
    if (!event_detail?.start_time || !event_detail?.end_time) {
      return <Label text="Unassigned Time" />;
    } else {
      return (
        <Label
          text={`${moment(event_detail?.start_time, 'hh:mm A').format('HH:MM A')} - ${moment(
            event_detail?.end_time,
            'hh:mm A'
          ).format('HH:MM A')}`}
        />
      );
    }
  };

  const getTotalGuests = () => {
    if (!event_detail?.total_guest) {
      return <Label text="Unassigned Guests" />;
    } else {
      return <Label text={`Total Guests: ${event_detail?.total_guest}`} />;
    }
  };

  const changeStatusMethod = async (status: number) => {
    if (!eventId) return;
    setIsStatusLoading(true);
    const res = await changeEventStatus(eventId, { event_id: Number(eventId), status: status });
    if (res?.success) {
      setEventData({ ...eventData, event_detail: { ...eventData.event_detail, status: status } });
      setIsStatusLoading(false);
      setIsCancelModalVisible(false);
      showToast({
        message: 'Success!',
        description: `Event ${status === 0 ? 'Uncancelled!!' : 'Cancelled!'}`
      });
      getEventDetailMethod(eventId);
    } else {
      setIsStatusLoading(false);
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  const sendMessageMethod = async (values: any) => {
    setIsSendMessageModalVisible(false);
    try {
      const res = await sendMessage({ ...values, event_id: eventId });
      if (res?.success) {
        showToast({
          message: 'Success!',
          description: `Message sent successfully!`
        });
      } else {
        showErrorToast({ message: 'Error!', description: `Please try again later.` });
      }
    } catch (error) {
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  const handleViewSurveyForm = (survey_url: string) => {
    updateRawData({ pdfUrl: survey_url });
    updateRawData({ showSurveyForm: true });
  };

  return (
    <EventHeaderWrapper>
      {location?.state?.prevPath === `/quote/view/${quoteId}` ||
      location?.state?.path === 'create_event' ||
      location?.state?.path === 'quote_details' ||
      location?.state?.path === 'quote_event' ? (
        <BreadCrumbs routes={EventBreadCrumbsViewEvent(quoteId)} />
      ) : (
        <BreadCrumbs routes={EventDetailsBreadCrumbs()} />
      )}

      {/* <PageTitle text="EVENT MANAGEMENT" isBack={false} path="/events">
        {isAdmin && (
          <Flex style={{ alignSelf: 'flex-end ' }} className="event-btn-wrapper">
            <Button
              variant="ghost"
              text={'SEND MESSAGE'}
              icon={<img src={Send} alt="message" />}
              onClick={() => setIsSendMessageModalVisible(true)}
            />
            <Button
              variant="ghost"
              text={getStatus()}
              icon={<img src={Cancel} alt="Cancel" />}
              onClick={() => setIsCancelModalVisible(true)}
            />
            <Button
              variant="ghost"
              text="EDIT"
              icon={<img src={EditIcon} alt="Edit" />}
              onClick={onEdit}
            />
          </Flex>
        )}
      </PageTitle> */}

      <Row gutter={18} style={{ marginTop: '20px' }}>
        <Col lg={12} md={12} xs={24}>
          <Row>
            <Col lg={24} md={24} xs={24} style={{ alignItems: 'center', verticalAlign: 'middle' }}>
              <PageTitle text="EVENT MANAGEMENT" isBack={true} path="/events"></PageTitle>
            </Col>
          </Row>
        </Col>
        <Col lg={12} md={12} xs={24}>
          <Row style={{ justifyContent: `${device !== 'mobile' ? 'end' : ''}` }}>
            {showSendFeedbackButton && (
              <Button
                style={{ fontSize: '16px' }}
                variant="ghost"
                text={'SUBMIT FEEDBACK'}
                icon={<img src={file} alt="submit feedback" />}
                onClick={() => eventId && navigate(`/events/survey/${eventId}`)}
              />
            )}
            {showViewSurveyFormButton && (
              <Button
                variant="ghost"
                text={'VIEW FOLLOW UP'}
                icon={<img src={file} alt="submit feedback" />}
                size="small"
                onClick={() => handleViewSurveyForm(event_detail?.survey_url)}
              />
            )}
            <Button
              style={{ fontSize: '16px' }}
              variant="ghost"
              text={'SEND MESSAGE'}
              icon={<img src={Send} alt="message" />}
              onClick={() => setIsSendMessageModalVisible(true)}
            />
            {isEmployee ? (
              ''
            ) : (
              <Button
                style={{ fontSize: '16px' }}
                variant="ghost"
                text={getStatus()}
                icon={<img src={Cancel} alt="Cancel" />}
                onClick={() => setIsCancelModalVisible(true)}
              />
            )}
            {/* {event_detail?.status !== 1 ? ( */}
            <Button
              style={{ fontSize: '16px' }}
              variant="ghost"
              text="EDIT"
              icon={<img src={EditIcon} alt="Edit" />}
              onClick={onEdit}
            />
            {/* ) : (
              ''
            )} */}
          </Row>
        </Col>
      </Row>

      <Row justify="space-between" style={{ paddingBottom: '20px', alignItems: 'baseline' }}>
        <Col lg={4} xs={24}>
          <SelectInput
            id="event_id"
            name="event_id"
            allowClear={false}
            placeholdertitle="Select from the list"
            style={{ width: 'auto', paddingTop: '20px' }}
            value={currentEventCode}
            onChange={(event_id: any) => {
              eventCodeChange(eventCodeList.find((event) => event.id === event_id));
            }}
          >
            {eventCodeList?.map((item) => (
              <Select.Option key={item?.id} value={item.id} id={item?.id}>
                {item?.text}
              </Select.Option>
            ))}
          </SelectInput>
        </Col>

        {/* <Col lg={4} xs={24}> */}
        {/* <Flex
            className="fix-mobile"
            justifyContent="flex-end"
            gap={10}
            bottom={24}
            // style={{ paddingBottom: 24 }}
          > */}
        <EventActionButtons
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          isDowloadPdfModalVisible={isDowloadPdfModalVisible}
          isContractDowloadPdfModalVisible={isContractDowloadPdfModalVisible}
          setIsDowloadPdfModalVisible={setIsDowloadPdfModalVisible}
          setIsContractDowloadPdfModalVisible={setIsContractDowloadPdfModalVisible}
          getEventDetailMethod={getEventDetailMethod}
          data={event_detail}
          locationDetail={venue_detail}
          hasBottomButtons={false}
        />
        <SurveyFormPdfViewer />
        {/* </Flex> */}
        {/* </Col> */}
      </Row>

      <div
        style={{
          margin: '15px 0px',
          background: '#eee',
          height: '1px'
        }}
      />

      <ConfirmModal
        title={`Are you sure you want to ${
          event_detail?.status === 4 ? 'uncancel' : 'cancel'
        } this event?`}
        okText="Yes"
        cancelText="No"
        visible={isCancelModalVisible}
        onOK={() => changeStatusMethod(event_detail?.status === 4 ? 0 : 4)}
        onCancel={() => setIsCancelModalVisible(false)}
      >
        This Event will {event_detail?.status === 4 && `not`} be tagged as “CANCELLED”{' '}
        {event_detail?.status === 4 && `anymore`}.
      </ConfirmModal>
      <SendMessageModal
        visible={isSendMessageModalVisible}
        onCancel={() => setIsSendMessageModalVisible(false)}
        onOk={(values: any) => sendMessageMethod(values)}
        staffList={[].concat(
          eventData?.event_staff_employees || [],
          eventData?.event_add_staff_employees || []
        )}
      />
    </EventHeaderWrapper>
  );
};

export default EventDetailsHeader;