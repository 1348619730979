import { Col, Row, Spin, Select } from 'antd';
import { MenuCategoryWrapper, MenuMenusWrapper } from './style';
import { getMenuAndProductSecDropdownData, handleKeyPress, handleUpdateCategory } from './helper';
import { useEffect, useState } from 'react';

import Error from '@atom/Error';
import { Flex } from '@atom/Flex';
import { Input } from '@atom/Input';
import { Label } from '@atom/FormLable';
import { MenuAndProductDetailsSecProps } from '@pages/MenuManagementPage/types';
import SelectInput from '@atom/Select';
import { showErrorToast } from '@utils/index';
import { getSelectOptions } from '@utils/helper';
import SubCategory from './SubCategory';
import { getMeasurements, getPrepList } from './SubCategory/helper';
import { useParams } from 'react-router';

const { Option } = Select;

function MenuAndProductDetailsSec(props: MenuAndProductDetailsSecProps) {
  const {
    formik,
    ingredientRef,
    submitIngredients,
    forQuotePopUp = false,
    setProductSubmit,
    productListFromQuote
  } = props;
  const { values, handleBlur, setFieldValue, setValues, handleChange, setErrors, touched, errors } =
    formik;

  const [dropdownData, setDropdownData] = useState<any>({});
  const [isMenuSecLoading, setIsMenuSecLoading] = useState<boolean>(true);
  const [measurementsData, setMeasurementsData] = useState([]);
  const [prepListData, setPrepListData] = useState([]);

  const { id: product_id } = useParams();

  useEffect(() => {
    updateDropdownData();
    getMeasurementMethod();
    getPrepListMethod();
  }, []);

  const getDefaultProductType = (
    data: {
      id: number;
      text: string;
    }[]
  ): void => {
    const productType = data?.find((item) => item?.text === 'One-Time Product');
    if (values?.product_type) return;
    setValues({
      ...values,
      product_type: productType?.id ?? 4,
      product_type_name: productType ? productType.text : 'Regular'
    });
  };

  const updateDropdownData = async () => {
    setIsMenuSecLoading(true);
    try {
      const ddRes = await getMenuAndProductSecDropdownData();
      const ddData = {
        menusList: ddRes?.[0]?.success ? ddRes?.[0]?.data : [],
        productTypeList: ddRes?.[1]?.success
          ? ddRes?.[1]?.data?.filter((i: any) => i?.text !== 'Archived')
          : [],
        catgoryList: ddRes?.[2]?.success ? ddRes?.[2]?.data : []
      };
      getDefaultProductType(ddData?.productTypeList);
      setDropdownData(ddData);
    } catch (error) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch dropdown data'
      });
    }
    setIsMenuSecLoading(false);
  };

  const getMeasurementMethod = async () => {
    const res = await getMeasurements();
    setMeasurementsData(res || []);
  };

  const getPrepListMethod = async () => {
    const res = await getPrepList();
    setPrepListData(res || []);
  };

  if (isMenuSecLoading) {
    return (
      <Flex justifyContent="center" style={{ minHeight: '200px' }}>
        <Spin />
      </Flex>
    );
  }

  const isSubCategoriesButtonDisabled = () => {
    return (
      !values?.category ||
      (values?.sub_category_array?.length > 0 &&
        values?.sub_category_array?.some((sub: any) => !sub?.id))
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Menu Names" />
          <MenuMenusWrapper>
            <SelectInput
              id={`menus`}
              name={`menus`}
              placeholdertitle="Select Menu(s)"
              value={values?.menus}
              error={touched?.menus && errors?.menus}
              onChange={(ids: any) => {
                setProductSubmit && setProductSubmit(false);
                setFieldValue(`menus`, ids);
              }}
              onBlur={handleBlur}
              mode="multiple"
              allowClear={false}
              filterOption={(value: any, option: any) =>
                option?.children?.props?.children?.[1]
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
              }
              onInputKeyDown={(e) =>
                handleKeyPress(
                  e,
                  'menusList',
                  'name',
                  'menus',
                  setDropdownData,
                  setFieldValue,
                  dropdownData,
                  values
                )
              }
            >
              <Option
                style={{
                  color: '#999',
                  fontSize: '16px',
                  padding: '6px 6px 6px 12px',
                  fontWeight: '400'
                }}
                value="disabled"
                disabled
              >
                <>Select Menu(s)</>
              </Option>
              {getSelectOptions(dropdownData?.menusList, 'name', true, values?.menus)}
            </SelectInput>
          </MenuMenusWrapper>
        </Col>
        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Product Type" />
          <SelectInput
            id={`product_type`}
            name={`product_type`}
            placeholdertitle="Select Product Type"
            value={values?.product_type}
            error={touched?.product_type && errors?.product_type}
            onChange={(id: any) => {
              setProductSubmit && setProductSubmit(false);
              setValues({
                ...values,
                product_type: id,
                product_type_name: dropdownData?.productTypeList?.find((i: any) => i?.id === id)
                  ?.text
              });
            }}
            onBlur={handleBlur}
          >
            <Option
              style={{
                color: '#999',
                fontSize: '16px',
                padding: '6px 6px 6px 12px',
                fontWeight: '400'
              }}
              value="disabled"
              disabled
            >
              <>Select One</>
            </Option>
            {getSelectOptions(dropdownData?.productTypeList, 'text')}
          </SelectInput>
        </Col>
      </Row>
      <Row className="margin-top-10" gutter={[8, 8]}>
        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Product Name" />
          <Input
            name="product_name"
            id="product_name"
            error={touched?.product_name && errors?.product_name}
            placeholder="Product Name"
            onChange={(e) => {
              handleChange(e);
              setProductSubmit && setProductSubmit(false);
            }}
            value={values?.product_name}
            onBlur={handleBlur}
          />
        </Col>
        <Col md={12} xs={24}>
          <Label isMandatory={true} text="Master Category" />
          <MenuCategoryWrapper>
            <SelectInput
              id={`category`}
              name={`category`}
              placeholdertitle="Select Categories"
              value={values?.category}
              error={touched?.category && errors?.category}
              onChange={(id: any) => {
                setProductSubmit && setProductSubmit(false);
                handleUpdateCategory(product_id as string, id, values, setValues);
              }}
              onBlur={handleBlur}
            >
              <Option
                style={{
                  color: '#999',
                  fontSize: '16px',
                  padding: '6px 6px 6px 12px',
                  fontWeight: '400'
                }}
                value="disabled"
                disabled
              >
                <>Select One</>
              </Option>
              {getSelectOptions(dropdownData?.catgoryList, 'name')}
            </SelectInput>
          </MenuCategoryWrapper>
        </Col>
      </Row>
      {/* <PrepUsersFields formik={formik}/> */}
      <Row gutter={[8, 8]}>
        <Col md={24} xs={24}>
          <SubCategory
            productListFromQuote={productListFromQuote}
            setProductSubmit={setProductSubmit}
            ingredientRef={ingredientRef}
            formik={formik}
            submitIngredients={submitIngredients}
            isSubCategoryDisabled={isSubCategoriesButtonDisabled()}
            subCategoriesArray={formik?.values?.sub_category_array || []}
            subCategoriesOption={
              dropdownData?.catgoryList?.find((i: any) => i?.id === values?.category)
                ?.sub_categories
            }
            measurementsData={measurementsData}
            prepListData={prepListData}
            forQuotePopUp={forQuotePopUp}
          />
          <p className="errormsg" style={{ marginTop: '10px' }}>
            {touched?.sub_categories && errors?.sub_categories ? (
              <Error error={errors?.sub_categories} />
            ) : (
              ' '
            )}
          </p>
          <Flex style={{ marginBottom: '15px' }} />
        </Col>
      </Row>
    </>
  );
}

export default MenuAndProductDetailsSec;
