import { Button } from '@atom/Buttons';
import { Flex } from '@atom/Flex';
import React, { useEffect, useState } from 'react';
import { EventModalContent, STATUS } from '../constants';
import EventModal from './EventModal';
import DownloadPDFModal from './DownloadPDFModal';
import { useSelector } from 'react-redux';
import {
  ViewContractDetails,
  dowloadEventPdf,
  generateContract,
  getContractDetails,
  getDownloadPdfData
} from './helper';
import { useParams } from 'react-router-dom';
import { showErrorToast, showToast } from '@utils/index';
import { AnchorButtonWrapper, ButtonWrapper, DropdownButtonWrapper } from './style';
import DownloadContractPDFModal from './DownloadContractPDFModal';
import { Dropdown, message } from 'antd';
import type { MenuProps } from 'antd';
import ContractButtonModal from './ContractButtonModal';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import CenterSpiner from '@atom/CenterSpiner';
const EventActionButtons = (props: any) => {
  const {
    isModalVisible,
    setIsModalVisible,
    isDowloadPdfModalVisible,
    isContractDowloadPdfModalVisible,
    setIsDowloadPdfModalVisible,
    setIsContractDowloadPdfModalVisible,
    getEventDetailMethod,
    data,
    locationDetail,
    hasBottomButtons
  } = props;
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [isRelease, setIsRelease] = useState<boolean>(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const isEventConfirmed = data?.status === STATUS.CONFIRM;
  const { eventId } = useParams();
  const device = useWindowSizeChangedforGrid();

  const [isContractButtonsModalVisible, setIsContractButtonsModalVisible] =
    useState<boolean>(false);
  const [isContractDataLoading, setIsContractDataLoading] = useState<boolean>(false);
  const [isViewContractDataLoading, setIsViewContractDataLoading] = useState<boolean>(false);
  const [isContractButtonLoading, setIsContractButtonLoading] = useState<boolean>(false);

  const [contractData, setContractData] = useState<any>(null);
  const [contractAction, setContractAction] = useState<string | null>(null);

  const user = useSelector((state: { user: { isAdmin: boolean } }) => state.user);
  const { isAdmin } = user;

  const updateModalContent = (title: string, subTitle: string, isRelease?: boolean) => {
    setTitle(title);
    setSubTitle(subTitle);
    setIsRelease(!!isRelease);
    setIsModalVisible(true);
  };

  const getContractDetailMethod = async (event_id?: string) => {
    if (!event_id) return;
    setIsContractDataLoading(true);
    try {
      const res = await getContractDetails(event_id);
      if (res?.success) {
        setContractData(res?.data);
        setIsContractDataLoading(false);
      }
    } catch (err) {
      setIsContractDataLoading(false);
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  const viewContractDetailMethod = async (event_id?: string, envelope_id?: string) => {
    if (!event_id) return;
    setIsViewContractDataLoading(true);
    try {
      const res = await ViewContractDetails(event_id, envelope_id!);
      if (res?.success) {
        window.open(res?.data?.url, '_blank');
        setIsViewContractDataLoading(false);
      }
    } catch (err) {
      setIsViewContractDataLoading(false);
      showErrorToast({ message: 'Error!', description: `Please try again later.` });
    }
  };

  useEffect(() => {
    if (eventId && data?.contract_status) {
      getContractDetailMethod(eventId);
    }
  }, [eventId, data?.contract_status]);

  const handleDownloadPdf = async () => {
    if (isAdmin) {
      setIsDowloadPdfModalVisible(true);
    } else {
      if (!data?.event_pdf) {
        setIsGeneratingPdf(true);
        const data = getDownloadPdfData(eventId);
        const res = await dowloadEventPdf(data, eventId);
        if (res?.error) {
          return showErrorToast({
            message: `Failed`,
            description: 'Please try again'
          });
        } else if (res?.data?.url) {
          window.open(res?.data?.url, '_blank');
        }
        setIsGeneratingPdf(false);
      } else {
        window.open(data?.event_pdf, '_blank');
      }
    }
  };

  const handleDownloadContractPdf = async () => {
    setIsContractDowloadPdfModalVisible(true);
  };

  const handleContractButtons = async () => {
    setIsContractButtonsModalVisible(true);
    setContractAction('1');
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleContractButtons();
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    let action;
    switch (e.key) {
      case '0':
        action = 'view';
        viewContractDetailMethod(eventId, contractData?.envelope_id);
        break;
      case '1':
        action = '2';
        setIsContractButtonsModalVisible(true);
        break;
      case '2':
        action = '4';
        handleContractButtonClick(action);
        break;
      case '3':
        action = '3';
        handleContractButtonClick(action);
        break;
      case '4':
        viewContractDetailMethod(eventId, contractData?.envelope_id);
        break;
      default:
        break;
    }
    if (action !== undefined) {
      setContractAction(action);
    }
  };

  const handleContractButtonClick = async (action: string) => {
    setIsContractButtonLoading(true);
    const res = await generateContract({
      recipient_details: contractData?.recipient_details,
      event_id: contractData?.event_id || data?.event_id,
      envelope_id: contractData?.envelope_id || '',
      deposit_amount: contractData?.deposit_amount,
      deposit_percent: contractData?.deposit_percent,
      is_deposit_percent: contractData?.is_deposit_percent,
      grand_total: contractData?.grand_total,
      action: action || ''
    });
    setIsContractButtonLoading(false);

    if (!res?.error) {
      if (res?.data?.data?.pdf_url) {
        window.open(res?.data?.data?.pdf_url, '_blank');
      }
      if (res?.data?.data?.msg) {
        showToast({ message: res?.data?.data?.msg, description: '' });
        window.location.reload();
      }
    } else {
      showErrorToast({
        message: 'Error Saving Documents',
        description: res.error || 'Please try again'
      });
    }
  };

  const contractStatus = data?.contract_status;
  const isContractButtonVisible = data?.status === STATUS.CONFIRM;
  const commonDisabled =
    !contractStatus ||
    contractStatus === 2 ||
    contractStatus === 3 ||
    contractStatus === 4 ||
    contractStatus === 6;

  const items: MenuProps['items'] = [
    {
      label: 'View Contract',
      key: '0',
      disabled: !contractStatus
    },
    {
      label: 'Modify Contract',
      key: '1',
      disabled: !contractStatus
    },
    {
      label: 'Resend Contract',
      key: '2',
      disabled: commonDisabled
    },
    {
      label: 'Cancel Contract',
      key: '3',
      disabled: commonDisabled || contractStatus === 5
    },
    {
      label: 'Download Contract',
      key: '4',
      disabled:
        !contractStatus ||
        contractStatus === 1 ||
        contractStatus === 4 ||
        contractStatus === 5 ||
        contractStatus === 6
    }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  const contract_status =
    data?.contract_status === 1
      ? 'Contract Sent'
      : data?.contract_status === 2
      ? 'Contract Rejected'
      : data?.contract_status === 3
      ? 'Contract Signed'
      : data?.contract_status === 4
      ? 'Contract Initiated'
      : data?.contract_status === 5
      ? 'Contract Expired'
      : data?.contract_status === 6
      ? 'Contract Cancelled'
      : 'Send Contract';

  return (
    <>
      <Flex
        className="footerButton fix-mobile"
        style={{ paddingTop: `${hasBottomButtons ? '20px' : '0px'}` }}
        direction={hasBottomButtons ? 'row' : 'column'}
      >
        <Flex gap={10} direction={device === 'mobile' ? 'column' : 'row'}>
          {isAdmin && !hasBottomButtons ? (
            <DropdownButtonWrapper>
              <Dropdown.Button
                menu={menuProps}
                onClick={handleButtonClick}
                loading={isViewContractDataLoading || isContractButtonLoading}
                buttonsRender={([leftButton, rightButton]) => [
                  React.cloneElement(leftButton as React.ReactElement<any, string>, {
                    disabled: data?.contract_status
                  }),
                  React.cloneElement(rightButton as React.ReactElement<any, string>, {
                    disabled: false
                  })
                ]}
              >
                {contract_status}
              </Dropdown.Button>
            </DropdownButtonWrapper>
          ) : null}
          {isAdmin && isEventConfirmed && hasBottomButtons ? (
            <Button
              text="Unconfirm Event"
              variant="ghost"
              style={{
                textDecoration: 'underline',
                fontWeight: '700',
                fontSize: '16px',
                marginLeft: 'auto',
                width: 'fit-content'
              }}
              onClick={() =>
                updateModalContent(
                  EventModalContent.Unconfirm_Title,
                  EventModalContent.Unconfirm_SubTitle
                )
              }
            />
          ) : null}
          {isAdmin && (
            <Button
              style={{ fontWeight: '700', fontSize: '16px' }}
              className={'responsive-style'}
              text={'Release Final Details'}
              variant={'secondary'}
              onClick={() =>
                updateModalContent(
                  EventModalContent.Release_Title,
                  EventModalContent.Release_SubTitle,
                  true
                )
              }
            />
          )}
          {!isAdmin && data?.event_pdf ? ( //rendering button in anchor tag conditionally to enable window open behaviour smoothly in mobile
            <AnchorButtonWrapper href={data?.event_pdf} target="_blank" style={{ color: '#000' }}>
              <Button
                style={{ fontWeight: '700', fontSize: '16px' }}
                className={'responsive-style'}
                text={'Download As PDF'}
                variant={'secondary'}
                isLoading={isGeneratingPdf}
                onClick={() => {
                  return;
                }}
              />
            </AnchorButtonWrapper>
          ) : (
            <Button
              style={{ fontWeight: '700', fontSize: '16px' }}
              className={'responsive-style'}
              text={'Download As PDF'}
              variant={'secondary'}
              isLoading={isGeneratingPdf}
              onClick={() => {
                handleDownloadPdf();
              }}
            />
          )}

          {!isAdmin && isEventConfirmed && (
            <Button
              style={{ fontWeight: '700', fontSize: '16px' }}
              className={'responsive-style'}
              text={isEventConfirmed ? 'Event Confirmed' : 'Confirm Event'}
              isDisable={isEventConfirmed}
              onClick={() =>
                updateModalContent(
                  EventModalContent.Confirm_Title,
                  EventModalContent.Confirm_SubTitle
                )
              }
            />
          )}

          {isAdmin && (
            <Button
              style={{ fontSize: '16px' }}
              className={'responsive-style'}
              text={isEventConfirmed ? 'Event Confirmed' : 'Confirm Event'}
              isDisable={isEventConfirmed}
              onClick={() =>
                updateModalContent(
                  EventModalContent.Confirm_Title,
                  EventModalContent.Confirm_SubTitle
                )
              }
            />
          )}
        </Flex>
        {isAdmin && isEventConfirmed && !hasBottomButtons ? (
          <ButtonWrapper>
            <Button
              text="Unconfirm Event"
              variant="ghost"
              style={{
                textDecoration: 'underline',
                fontWeight: '700',
                fontSize: '16px',
                marginLeft: 'auto'
              }}
              onClick={() =>
                updateModalContent(
                  EventModalContent.Unconfirm_Title,
                  EventModalContent.Unconfirm_SubTitle
                )
              }
            />
          </ButtonWrapper>
        ) : null}
      </Flex>

      <EventModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        title={title}
        subTitle={subTitle}
        isRelease={isRelease}
        isConfirmUnconfirmEvent={
          title === EventModalContent.Confirm_Title || isEventConfirmed ? true : false
        }
        status={isEventConfirmed ? STATUS.UNCONFIRM : STATUS.CONFIRM}
        getEventDetailMethod={getEventDetailMethod}
        onCancel={() => {
          setIsRelease(false);
          setIsModalVisible(false);
        }}
      />
      <DownloadPDFModal
        data={data}
        isDowloadPdfModalVisible={isDowloadPdfModalVisible}
        setIsDowloadPdfModalVisible={setIsDowloadPdfModalVisible}
        onCancel={() => {
          setIsDowloadPdfModalVisible(false);
        }}
      />
      <DownloadContractPDFModal
        data={data}
        locationDetail={locationDetail}
        isContractDowloadPdfModalVisible={isContractDowloadPdfModalVisible}
        setIsContractDowloadPdfModalVisible={setIsContractDowloadPdfModalVisible}
        onCancel={() => {
          setIsContractDowloadPdfModalVisible(false);
        }}
      />
      <ContractButtonModal
        data={data}
        locationDetail={locationDetail}
        contractData={contractData}
        isContractButtonsModalVisible={isContractButtonsModalVisible}
        isViewContractDataLoading={isViewContractDataLoading}
        setIsContractButtonsModalVisible={setIsContractButtonsModalVisible}
        onCancel={() => {
          setIsContractButtonsModalVisible(false);
        }}
        contractAction={contractAction}
      />
    </>
  );
};

export default EventActionButtons;
