import React from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from '@atom/Buttons';
import { Col } from 'antd';
import { FieldArray } from 'formik';
import ClientItem from './ClientItem';
import { RECIPIENT_DETAILS } from './helper';
import Error from '@atom/Error';
import { Flex } from '@atom/Flex';

function ClientContainer(props: any) {
  const {
    recipient_details,
    handleChange,
    errors,
    touched,
    handleBlur,
    isFormEnabled,
    setFieldValue
  } = props;

  return (
    <>
      <FieldArray
        name="recipient_details"
        render={(_arrrayHelper: any) => {
          return (
            <>
              {recipient_details.map((i: any, index: number) => (
                <ClientItem
                  key={i.id || i.localId}
                  handleChange={handleChange}
                  index={index}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  isFormEnabled={isFormEnabled}
                  recipient_details={recipient_details}
                  touched={touched.recipient_details && touched.recipient_details[index]}
                  errors={errors.recipient_details && errors.recipient_details[index]}
                  item={recipient_details[index]}
                  onRemove={(item: any) => {
                    const newRerentatives = recipient_details.filter(
                      (i: any) => i.id !== item.id || i.localId !== item.localId
                    );
                    setFieldValue('recipient_details', newRerentatives);
                  }}
                />
              ))}
            </>
          );
        }}
      />
      <Flex style={{ marginBottom: 8 }}>
        {
          recipient_details
            .map((_i: any, index: number) => {
              if (
                (touched.recipient_details?.[index]?.is_signer ||
                  touched.recipient_details?.[index]) &&
                errors.recipient_details?.[index]?.is_signer
              ) {
                return <Error error={errors.recipient_details?.[index]?.is_signer} key={index} />;
              }
              return null;
            })
            .filter(Boolean)[0]
        }
      </Flex>
      {recipient_details?.length < 4 && (
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button
            text={'Add Client'}
            variant={'secondary'}
            onClick={() => {
              setFieldValue('recipient_details', [
                ...recipient_details,
                { ...RECIPIENT_DETAILS, localId: Math.random() }
              ]);
            }}
            isDisable={!isFormEnabled}
            style={{ marginBottom: 18 }}
            icon={<PlusCircleOutlined />}
          />
        </Col>
      )}
    </>
  );
}

export default ClientContainer;