import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import LoginPage from '@pages/LoginPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import { Route, Routes } from 'react-router';
import { AppContainer } from './styles';
import ContractView from '@pages/EventManagementPage/ContractView';
import { NotFoundPageContainer } from '@pages/LoginPage/styles';
import NotFoundPage from '@pages/NotFoundPage';

function AuthRoutes() {
  return (
    <AppContainer>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/forgot-password/reset/:passwordVerifyString"
          element={<ResetPasswordPage />}
        />
        <Route path="/contract-view/:token/:id" element={<ContractView />} />
        <Route
          path="/contract-view"
          element={
            <NotFoundPageContainer>
              <NotFoundPage />
            </NotFoundPageContainer>
          }
        />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </AppContainer>
  );
}

export default AuthRoutes;
