import { MinusCircleOutlined } from '@ant-design/icons';
import Checkbox from '@atom/Checkbox';
import { Label } from '@atom/FormLable';
import { Input } from '@atom/Input';
import { Col, Grid, Row } from 'antd';
import { Field } from 'formik';

const { useBreakpoint } = Grid;
function ClientItem(props: any) {
  const {
    recipient_details,
    item,
    handleChange,
    errors,
    touched,
    handleBlur,
    isFormEnabled,
    onRemove,
    index
  } = props;

  const itemString = `recipient_details[${index}]`;

  const screens = useBreakpoint();

  const selectedSignersCount = recipient_details.filter(
    (detail: { is_signer: number }) => detail.is_signer
  ).length;

  const isViewOnly = !item.is_signer && selectedSignersCount >= 2;

  return (
    <Row style={{ marginBottom: screens.xl ? 0 : 18 }} align="middle" gutter={8}>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        <Label text={`CLIENT NAME #${index + 1}`} isMandatory={true} />
        <Input
          id={`${itemString}.name`}
          name={`${itemString}.name`}
          placeholder="Enter Name"
          value={item?.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.name && errors?.name}
          disabled={!isFormEnabled}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        <Label text={`CLIENT EMAIL #${index + 1}`} isMandatory={true} />
        <Input
          id={`${itemString}.email`}
          name={`${itemString}.email`}
          placeholder="Enter email"
          value={item?.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched?.email && errors?.email}
          disabled={!isFormEnabled}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={8}>
        <Row align="middle" gutter={8}>
          <Col xs={20} sm={20} md={20} xl={20} lg={20}>
            <Field name={`${itemString}.is_signer`}>
              {({ field, form }: any) => (
                <Checkbox
                  name={isViewOnly ? 'View Only' : 'Needs Signature'}
                  id={field.name}
                  onChange={(e: { target: { checked: any } }) => {
                    const isChecked = e.target.checked ? 1 : 0;
                    form.setFieldValue(`${itemString}.is_signer`, isChecked);
                    form.setFieldTouched(field.name, true, true);
                  }}
                  disabled={!isFormEnabled || isViewOnly}
                  checked={item?.is_signer}
                />
              )}
            </Field>
          </Col>
          <Col xs={4} sm={4} md={4} xl={4} lg={4}>
            {recipient_details?.length > 1 && (
              <MinusCircleOutlined
                style={{ fontSize: 24, cursor: isFormEnabled ? 'pointer' : 'not-allowed' }}
                onClick={() => isFormEnabled && onRemove(item)}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ClientItem;