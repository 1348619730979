import { Button } from '@atom/Buttons';
import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import { Note } from '@atom/Note';
import { H2Typography } from '@constant/typography/Typography';
import { CREATE_EVENT_TAB_KEYS } from '@pages/EventManagementPage/AddEditEvent/helper';
import { STATUS } from '@pages/QuoteManagementPage/constants';
import { QUOTE_MANAGEMENT_TABS } from '@pages/QuoteManagementPage/QuotesList/helper';
import { GetTabData, PutTabData } from '@services/quoteServices';
import { showErrorToast, showToast, updateRawData } from '@utils/index';
import { Col, Grid, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditQuoteFooter from '../AddEditQuoteFooter';
import { getTabBaseUrl, QuoteBasicDetailsObject } from '../helper';
import MealOrder from './MealOrder';
import { CustomNoteWrapper } from '@atom/Note/style';

const { useBreakpoint } = Grid;

interface MealOrderFormProps {
  tabKey: string;
  quoteBasicDetails: QuoteBasicDetailsObject;
  setQuoteBasicDetails: React.Dispatch<React.SetStateAction<QuoteBasicDetailsObject>>;
  submitForm?: (tabKey: string, values: any, actions: any, method?: string) => void;
  isEventsTab?: boolean;
}

const MealOrderForm = (props: MealOrderFormProps) => {
  const { tabKey, quoteBasicDetails, setQuoteBasicDetails, submitForm, isEventsTab } = props;
  const { quoteId, eventId } = useParams();
  const { random_unique_invoice_string, mealOrders, activeAccordion } = useSelector(
    (state: any) => state.rawData
  );

  const screens = useBreakpoint();

  const [draftSubmitting, setDraftSubmitting] = useState(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [eventDatesData, setEventDatesData] = useState<any>({});
  const [status, setStatus] = useState<any>(null);
  const [quotationNo, setQuotationNo] = useState<string>('');
  const navigate = useNavigate();
  const [nextLoading, setNextLoading] = useState<boolean>(false);

  const location = useLocation();

  const unique_invoice_string = useSelector((state: any) => state.rawData?.unique_invoice_string);

  const isCollapsed = activeAccordion?.length === 0;

  const handleExpandAll = () => {
    const defaultActiveKey = Array.from({ length: mealOrders?.length }, (v, k) => k);
    const accordionState = isCollapsed ? defaultActiveKey : [];
    updateRawData({ activeAccordion: accordionState });
  };

  const updateInitialData = async () => {
    setPageLoading(true);
    try {
      //@ts-ignore
      const res = await GetTabData(tabKey, quoteId, isEventsTab);
      !unique_invoice_string &&
        updateRawData({ unique_invoice_string: res?.data?.unique_invoice_string ?? '' });
      if (res?.success) {
        updateRawData({ mealOrders: res?.data?.subcategory_order });
        setEventDatesData(res?.data?.event_dates);
        setStatus(res?.data?.status);
        setQuotationNo(res?.data?.quotation_no);
        setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
          ...prev,
          id: res?.data?.id,
          quotation_no: res?.data?.quotation_no,
          status: res?.data?.status,
          unique_invoice_string: random_unique_invoice_string
            ? random_unique_invoice_string
            : res?.data?.unique_invoice_string
        }));
      } else {
        showErrorToast({
          message: 'Something went wrong',
          description: 'Failed to fetch meal order data.'
        });
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Something went wrong',
        description: 'Failed to fetch meal order data.'
      });
    }
    setPageLoading(false);
  };

  const submitHandler = async (values: any, actions: any, isDraft?: boolean) => {
    if (!isDraft && submitForm) {
      await submitForm(
        tabKey,
        {
          ...values,
          unique_invoice_string,
          id: quoteId,
          quotation_no: quotationNo
        },
        actions,
        'post'
      );
      setNextLoading(false);
      return;
    }
    const res: any = await PutTabData(tabKey, {
      ...quoteBasicDetails,
      ...values,
      status: isDraft ? values?.status : status,
      unique_invoice_string: random_unique_invoice_string
        ? random_unique_invoice_string
        : quoteBasicDetails.unique_invoice_string,
      id: quoteId
    });
    if (res?.success) {
      setQuoteBasicDetails((prev: QuoteBasicDetailsObject) => ({
        ...prev,
        id: res?.data?.id || prev?.id,
        quotation_no: res?.data?.quotation_no || prev?.quotation_no,
        status: res?.data?.status,
        unique_invoice_string: random_unique_invoice_string
          ? random_unique_invoice_string
          : res?.data?.unique_invoice_string || prev?.unique_invoice_string
      }));
      const tabBaseUrl = getTabBaseUrl(
        location?.pathname,
        res?.data?.id || quoteId,
        quoteBasicDetails,
        res?.data?.id
      );
      navigate(isDraft ? `/quote/tab/${QUOTE_MANAGEMENT_TABS.DRAFTS}` : `${tabBaseUrl}labour`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
      if (isDraft) {
        showToast({
          message: 'Success!',
          description: 'Quotation saved as Draft'
        });
      }
    } else {
      actions?.setErrors(res?.error);
      showErrorToast({
        message: 'Failed!',
        description: 'Something Went Wrong!'
      });
    }
    setNextLoading(false);
  };

  const backHandler = () => {
    if (isEventsTab) {
      const tabBaseUrl = `/events/create/${CREATE_EVENT_TAB_KEYS.MEALPLAN}/${quoteId}/${eventId}`;
      navigate(tabBaseUrl, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    } else {
      const tabBaseURL = getTabBaseUrl(location.pathname, quoteId, quoteBasicDetails, eventId);
      navigate(`${tabBaseURL}meal-plan`, {
        state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
      });
    }
  };
  const cancelHandler = () => {
    if (isEventsTab) {
      navigate('/events');
    } else {
      navigate('/quote');
    }
  };

  const saveAsDraftHandler = async () => {
    setDraftSubmitting(true);
    await submitHandler(
      { subcategory_order: mealOrders, status: STATUS.DRAFT, event_dates: eventDatesData },
      null,
      true
    );
    setDraftSubmitting(false);
  };
  const nextHandler = () => {
    setNextLoading(true);
    submitHandler({ subcategory_order: mealOrders, event_dates: eventDatesData }, null);
  };

  useEffect(() => {
    if (tabKey === 'meal-order') {
      updateInitialData();
    }
  }, [tabKey]);

  if (pageLoading) {
    return <CenterSpiner />;
  }

  return (
    <Flex
    direction="column"
    justifyContent="space-between"
    style={{ minHeight: mealOrders?.length && '63vh' }}
  >
      <div style={{ flexGrow: 1 }}>
        <Row gutter={16} style={{ marginTop: '32px' }}>
          <Col span={24}>
            <H2Typography style={{ fontWeight: 'normal' }}>Meal Subcategory Order</H2Typography>
            <div
              style={{
                marginTop: '18px',
                background: '#E0E0E0',
                marginBottom: '8px',
                height: '1px'
              }}
            />
          </Col>
        </Row>
        {mealOrders?.length &&
        <Flex
          gap={screens?.lg ? 8 : 0}
          direction={screens?.lg ? 'row' : 'column'}
          justifyContent="center"
          alignItems={screens?.lg && 'center'}
        >
          <CustomNoteWrapper>
            <Note description="Drag-and-drop functionality for meals is enabled when all accordions are closed. Close all accordions to rearrange meals." />
          </CustomNoteWrapper>
          
          <Button
            text={isCollapsed ? 'Expand all' : 'Collapse all'}
            onClick={handleExpandAll}
            style={{ marginBottom: 16 }}
            variant={'secondary'}
          />
        </Flex>}
        <Row gutter={16}>
          <Col xs={24}>
            <Flex direction="column">
              <MealOrder />
            </Flex>
          </Col>
        </Row>
      </div>
      <AddEditQuoteFooter
        showBackButton={true}
        isDraftSubmitting={draftSubmitting}
        backClickHandler={backHandler}
        cancelClickHandler={cancelHandler}
        saveAsDraftClickHandler={saveAsDraftHandler}
        nextClickHandler={nextHandler}
        isEvent={isEventsTab}
        isSubmitting={nextLoading}
      />
    </Flex>
  );
};

export default MealOrderForm;
